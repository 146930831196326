import React from 'react';
import { SwiperComponent } from 'vitl-component-library';

import Image from '@components/image/image';
import { setIconColor } from '../../looking-for-vitamins/looking-for-vitamins';

import styles from './single-card-slider.module.scss';

interface IProps {
  title?: string;
  slides: Array<{
    text: string;
    icon: string;
    text_mobile_underlined: string;
    text_mobile: string;
    image: string;
    filename_disk: string;
    description?: string;
  }>;
  description?: string;
  descriptionOnTop?: boolean;
  withArrow?: boolean;
  background?: string;
  mobileSize: number;
  desktopSize: number;
}

const SingleCardSlider: React.FC<IProps> = ({
  title,
  slides,
  descriptionOnTop,
  withArrow,
  description,
  background = '',
  mobileSize,
  desktopSize,
}) => {
  return (
    <section className={styles['section' + background]}>
      {withArrow && (
        <div className={styles.arrowContainer}>
          <img alt="Down arrow" width="18" src={'/images/icon-long-arrow-down.svg'} />
        </div>
      )}
      <div className={styles.container}>
        {description && <h2 className={styles.sectionTitle}>{title}</h2>}
        <div className={styles.swiperContainer}>
          <SwiperComponent paginationType="bullets" spaceBetween={20} slidesPerView={1}>
            {slides.map(item => {
              return (
                <div className={styles.swiperCard}>
                  {descriptionOnTop && (
                    <div className={styles.descriptionTop}>
                      <h3>
                        {item.text_mobile}
                        <span className={styles.underline}>{item.text_mobile_underlined}</span>
                      </h3>
                      <img
                        src={`${process.env.GATSBY_MEDIA_URL}${item.icon}?w=40&color=${setIconColor(item.text)}`}
                        alt={item.text}
                      />
                    </div>
                  )}
                  <Image filename={item.filename_disk || item.image} width={desktopSize} />
                </div>
              );
            })}
          </SwiperComponent>
        </div>
        {description && <p className={styles.sectionDescription}>{description}</p>}
      </div>
    </section>
  );
};

export default SingleCardSlider;
