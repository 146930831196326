import React, { useState } from 'react';
import styles from './looking-for-vitamins.module.scss';
import { Link } from 'gatsby';
import Image from '@components/image/image';
import SingleCardSlider from '../sliders/single-card-slider';

interface IProps {
  title: string;
  items: Array<any>;
  buttonLabel?: string;
  footnote?: any;
}

export const setIconColor = (item: string) => {
  switch (item) {
    case 'Energy':
      return 'FDC900';
    case 'Digestion':
      return '84CB9D';
    case 'Skin':
      return '77D4BC';
    default:
      return '';
  }
};

const LookingForVitamins: React.FC<IProps> = ({ title, items, buttonLabel, footnote = '' }) => {
  const [active, setActive] = useState(0);

  return (
    <section className={styles.sectionDarkerCream}>
      <div className={styles.container}>
        <div className={styles.desktopWrapper}>
          <div className={styles.row}>
            <div className={styles.imageWrapper}>
              <Image filename={`${items ? items[active].image : ''}`} width={475} />
            </div>
            <div className={styles.contentWrapper}>
              <h2>{title}</h2>
              <div className={styles.itemsWrapper}>
                {items?.map((item, index) => (
                  <div
                    className={index === active ? styles.activeItem : styles.item}
                    onMouseEnter={() => setActive(index)}
                  >
                    <img
                      src={`${process.env.GATSBY_MEDIA_URL}${item.icon}?w=40&color=${setIconColor(item.text)}`}
                      alt={item.text}
                    />
                    <h3>{item.text}</h3>
                  </div>
                ))}
              </div>
              <Link className={styles.button} to={'/consultation'}>
                {buttonLabel}
              </Link>
              {Array.isArray(footnote) ? footnote.map((note: string) => <p>{note}</p>) : <p>{footnote}</p>}
            </div>
          </div>
        </div>

        <div className={styles.mobileWrapper}>
          <SingleCardSlider slides={items} mobileSize={345} desktopSize={345} descriptionOnTop={true} />
          <Link className={styles.button} to={'/consultation'}>
            {buttonLabel}
          </Link>
          {Array.isArray(footnote) ? footnote.map((note: string) => <p>{note}</p>) : <p>{footnote}</p>}
        </div>
      </div>
    </section>
  );
};

export default LookingForVitamins;
