import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import SingleCardSlider from '../components/sliders/single-card-slider';
import Reviews from '@components/reviews/reviews';
import HowItWorks from '../components/how-it-works';
import ExpertApproval from '../components/expert-approval';
import LookingForVitamins from '../components/looking-for-vitamins';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
// styles
import styles from './tube.module.scss';
import Image from '@components/image/image';
import PartnersSlider from '@src/components/partners-slider/partners-slider';

const Tube: React.FC = () => {
  const content = useStaticQuery(graphql`
    query Tube {
      directusTube {
        page_title
        page_description
        hero_title
        hero_subtitle
        hero_features_list {
          text
        }
        hero_button_label
        hero_footnote
        trustpilot {
          title
          filename_disk
        }
        hero_image {
          title
          filename_disk
        }
        personalised_title
        personalised_slider_items {
          filename_disk
        }
        personalised_description
        clean_ingredients_title
        clean_ingredients_description
        clean_ingredients_image {
          filename_disk
        }
        hiw_title
        hiw_items {
          filename_disk
          title
          description
        }
        get_started_title
        get_started_items {
          image
          text
          icon
          text_mobile
          text_mobile_underlined
        }
        get_started_button_label
        get_started_footnote
        get_started_footnote_2
        expert_title
        expert_description
        expert_items {
          filename_disk
          name
          role
          qualification
        }
        faq_title
        faq_description
        faqs {
          question
          answer
        }
      }
    }
  `);

  const { directusTube } = content;

  return (
    <Layout>
      <SEO title={directusTube.page_title} description={directusTube.page_description} />

      <section className={styles.sectionPrimary}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.heroImage}>
              <Image
                filename={directusTube.hero_image.filename_disk}
                alt={directusTube.hero_image.title}
              />
            </div>
            <div className={styles.heroContentWrapper}>
              <h1>{directusTube.hero_title}</h1>
              <h2>{directusTube.hero_subtitle}</h2>
              <div className={styles.heroFeaturesList}>
                {directusTube.hero_features_list.map((x: { text: string }, index: number) => (
                  <p key={index}>
                    <img
                      src="/images/icon-compare-check.svg"
                      width="16"
                      className={styles.tickIcon}
                      alt=""
                    />
                    {x.text}
                  </p>
                ))}
              </div>

              <Link className={styles.button} to={'/consultation'}>
                {directusTube.hero_button_label}
              </Link>

              <div
                className={styles.heroFootnote}
                dangerouslySetInnerHTML={{
                  __html: directusTube.hero_footnote,
                }}
              />
              <div className={styles.trustpilotWrap}>
                <Image
                  filename={directusTube.trustpilot.filename_disk}
                  alt={directusTube.trustpilot.title}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <PartnersSlider isWhite />

      <SingleCardSlider
        slides={directusTube.personalised_slider_items}
        title={directusTube.personalised_title}
        background={'DarkerCream'}
        description={directusTube.personalised_description}
        mobileSize={345}
        desktopSize={744}
      />

      <section className={styles.sectionWhite}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.cleanImageWrapper}>
              <Image width={600} filename={directusTube.clean_ingredients_image.filename_disk} />
            </div>
            <div className={styles.cleanContentWrapper}>
              <h2>{directusTube.clean_ingredients_title}</h2>
              <p>{directusTube.clean_ingredients_description}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Reviews component doesn't receive any review data, should check if we need this
      <Reviews /> */}

      <HowItWorks title={directusTube.hiw_title} items={directusTube.hiw_items} />

      <LookingForVitamins
        title={directusTube.get_started_title}
        buttonLabel={directusTube.get_started_button_label}
        footnote={[directusTube.get_started_footnote, directusTube.get_started_footnote_2]}
        items={directusTube.get_started_items}
      />

      <ExpertApproval
        title={directusTube.expert_title}
        description={directusTube.expert_description}
        experts={directusTube.expert_items}
      />

      <StillHaveQuestion accordion={directusTube.faqs} description={directusTube.faq_description} />
    </Layout>
  );
};

export default Tube;
